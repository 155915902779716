import React from "react";
import Layout from "./Layout";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import { ReactComponent as MainLogo } from "../assets/MainLogo.svg";
import { theme } from "../config/theme";

const pages = [
  { label: "Mission", link: "missionSec" },
  { label: "Piattaforma", link: "piattaformaSec" },
  { label: "Team", link: "teamSec" },
  { label: "Contatti", link: "contattiSec" },
];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const NavigateToSection = (sectionId) => {
    setAnchorElNav(null);
    document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <AppBar position="static">
      <Layout py={0}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <MainLogo width="200px" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ aspectRatio: 1 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.label}
                  onClick={() => NavigateToSection(page.link)}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
            }}
          >
            <MainLogo width="200px" />
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Button
                variant="text"
                key={page.label}
                onClick={() => NavigateToSection(page.link)}
                disableRipple
                sx={{
                  color: "#000",
                  display: "block",
                  mx: 1,
                  "&:hover": {
                    backgroundColor: "inherit", // Remove hover effect
                    boxShadow: "none", // Remove hover shadow
                  },
                  "&:active": {
                    boxShadow: "none", // Remove click shadow
                  },
                  "&:focus": {
                    boxShadow: "none", // Remove focus shadow
                  },
                }}
              >
                {page.label}
              </Button>
            ))}
          </Box>
        </Box>
      </Layout>
    </AppBar>
  );
}

export default Navbar;
