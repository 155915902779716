import React from "react";
import Section from "./Section";
import { Box, Container, Typography } from "@mui/material";
import mission from "../assets/mission.jpg";

function Mission() {
  return (
    <Section p={6}>
      <Box
        id="missionSec"
        component="div" // Render as a div element
        sx={{
          backgroundImage: `url(${mission})`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
          height: { xs: "550px", lg: "700px" },
          width: "100%",
        }}
      >
        <Container maxWidth="sm" sx={{ textAlign: "center", pt: 8 }}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            Mai più frodi
          </Typography>
          <Typography variant="h3">
            Donazioni trasparenti e tracciabili, nel totale rispetto della
            privacy.
          </Typography>
        </Container>
      </Box>
    </Section>
  );
}

export default Mission;
