import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Layout from "./Layout";

import hero from "../assets/hero.png";

function Hero() {
  return (
    <Layout py={2}>
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          container
          flexDirection="column"
          justifyContent="space-around"
          alignItems="left"
          gap={2}
        >
          <Typography variant="h1" mt={4}>
            Rendi trasparente la tua campagna di beneficenza con{" "}
            <span className="no-wrap">C-Chain</span>
          </Typography>
          <Typography variant="body1">
            Un sistema di tracciamento delle donazioni basato sulla tecnologia
            blockchain che offre vantaggi a tutti gli stakeholder coinvolti
            nella campagna.
          </Typography>
          <Button
            variant="contained"
            sx={{ width: { lg: "50%", md: "75%" } }}
            size="large"
            onClick={() => {
              document
                .getElementById("contattiSec")
                ?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            Scopri come funziona
          </Button>
        </Grid>
        <Grid
          item
          md={6}
          textAlign={{ sm: "center", md: "right" }}
          alignContent="center"
        >
          <Box
            component="img"
            src={hero}
            sx={{ borderRadius: "20px" }}
            width="85%"
          ></Box>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Hero;
