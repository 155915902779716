import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#F18A00",
      dark: "#FDB04A",
    },
    secondary: {
      main: "#7DD181",
      light: "#b6f0b9",
    },
    text: {
      primary: "#000",
      secondary: "#FFF",
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
});

theme = createTheme(theme, {
  typography: {
    fontFamily: "Karla", //Main font
    h1: {
      fontFamily: "Caveat Brush",
      fontSize: "60pt",
      lineHeight: "54pt",
      letterSpacing: "-3px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "50pt",
        lineHeight: "48pt",
      },
    },
    h2: {
      fontFamily: "Caveat Brush",
      fontSize: "50pt",
      lineHeight: "44pt",
      letterSpacing: "-3px",
    },
    h3: {
      fontFamily: "Karla",
      fontSize: "24pt",
      lineHeight: "26pt",
      fontWeight: 400,
    },
    h4: {
      fontFamily: "Karla",
      fontSize: "22pt",
      lineHeight: "22pt",
      fontWeight: 400,
    },
    h6: {
      fontSize: "18px",
      fontWeight: 500,
    },
    body1: {
      fontFamily: "Karla",
      fontSize: "18pt",
      lineHeight: "20pt",
      fontWeight: 300,
    },
    footer: {
      fontFamily: "Karla",
      fontSize: "12pt",
      lineHeight: "15pt",
      fontWeight: 300,
    },
  },
});

theme = createTheme(theme, {
  components: {
    /* AppBar */
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff", // White background
          boxShadow: "none", // Remove elevation (shadow)
        },
      },
    },

    /* Buttons */
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white", // Force white text
        },
      },
    },

    /* TextField */
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: theme.palette.secondary.light,
          },
          "& .MuiInputLabel-root": {
            color: "black", // Set label color to black
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "black", // color when focused
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
            },
            "&:hover fieldset": {
              borderColor: "rgba(0, 0, 0, 0.87)", // Hover border color
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(0, 0, 0, 0.87)", // Hover border color
            },
          },
        },
      },
    },
  },
});

export { theme };
