import { Box } from "@mui/material";
import React from "react";

function Section({ children, p }) {
  return (
    <Box component="section" sx={{ paddingBottom: p, paddingTop: p }}>
      {children}
    </Box>
  );
}

export default Section;
