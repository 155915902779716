import React from "react";
import Layout from "./Layout";
import { Box, Grid, Typography } from "@mui/material";
import Card from "./Card";

import { ReactComponent as GestionaleIcon } from "../assets/desktop-icon.svg";
import { ReactComponent as MobileIcon } from "../assets/Mobile-icon.svg";
import { ReactComponent as BlockchainIcon } from "../assets/blockchain-icon.svg";
import { ReactComponent as SitoIcon } from "../assets/Web-icon.svg";

function Platform() {
  const cards = [
    {
      title: "Gestionale",
      text: "L’organizzatore della campagna benefica potrà gestire l’anagrafica e il magazzino attraverso una dashboard facile e intuitiva. Inoltre potrà monitorare tutti gli spostamenti delle donazioni ricevute.",
      Icon: GestionaleIcon,
    },
    {
      title: "Mobile App",
      text: "I volontari nei centri di raccolta avranno la possibilità di scansionare barcode o qrcode identificativi dei prodotti donati usando una comoda app per Android e iOS, velocizzando la raccolta dati e riducendo errori.",
      Icon: MobileIcon,
    },
    {
      title: "Blockchain",
      text: "Dietro le quinte C-Chain tokenizza i prodotti donati, permettendone un tracciamento trasparente su blockchain. Questo permette a tutti gli stakeholder di monitorare i flussi delle donazioni, riducendo la possibilità di frodi.",
      Icon: BlockchainIcon,
    },
    {
      title: "Sito Web",
      text: "Il donatore può rimanere coinvolto nella campagna e tracciare la propria donazione lungo tutta la filiera, nel totale rispetto della privacy. Potrà inoltre visualizzare informazioni e statistiche dell’intera campagna benefica.",
      Icon: SitoIcon,
    },
  ];
  return (
    <Layout>
      <Box id="piattaformaSec">
        <Typography variant="h2" sx={{ textAlign: "center", mb: 4 }}>
          Piattaforma C-Chain
        </Typography>
        <Typography
          variant="body1"
          sx={{ maxWidth: { lg: "50%", md: "75%" }, mb: 4 }}
        >
          La piattaforma C-Chain è un sistema modulare che permette a tutte le
          organizzazioni coinvolte nella campagna di beneficenza e ai donatori
          di monitorarne i dati in tempo reale.
        </Typography>
        <Grid container spacing={6}>
          {cards.map((card) => (
            <Grid item lg={3} sm={6} xs={12} key={card.title}>
              <Card title={card?.title} text={card?.text} Icon={card?.Icon} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  );
}

export default Platform;
