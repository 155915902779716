import React, { useRef } from "react";
import Layout from "./Layout";
import { Button, Grid, TextField, Typography } from "@mui/material";

import { ReactComponent as MailIcon } from "../assets/mail-icon.svg";

function Contact() {
  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const organizationRef = useRef(null);
  const messageRef = useRef(null);

  const handleSendEmail = () => {
    const email = emailRef.current.value;
    const name = nameRef.current.value;
    const organization = organizationRef.current.value;
    const message = messageRef.current.value;

    const subject = `Richiesta informazioni C-Chain da: ${name} - ${organization} - ${email}`;

    const mailtoLink = `mailto:info@c-chain.org?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <Layout>
      <Grid
        container
        spacing={6}
        sx={{ mb: 4 }}
        id="contattiSec"
        textAlign="center"
      >
        <Grid item md={6}>
          <Typography variant="h2">Contattaci</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item md={6}>
          <Grid container gap={2}>
            <TextField label="Nome" fullWidth inputRef={nameRef} />
            <TextField
              label="Azienda o Organizzazione"
              fullWidth
              inputRef={organizationRef}
            />
            <TextField label="Email" fullWidth inputRef={emailRef} />
            <TextField
              label="Messaggio"
              fullWidth
              multiline
              rows={6}
              inputRef={messageRef}
            />
            <Button
              variant="contained"
              size="large"
              sx={{ width: "50%", height: "60px" }}
              startIcon={<MailIcon />}
              onClick={handleSendEmail}
            >
              Invia
            </Button>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container alignItems="center" gap={4}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "80px", height: "80px", borderRadius: "50%" }}
              onClick={() => {
                window.location.href = `mailto:info@c-chain.org?subject=${encodeURIComponent(
                  "Richiesta informazioni C-Chain"
                )}`;
              }}
            >
              <MailIcon width="50px" height="50px" />
            </Button>
            <Typography variant="h3">info@c-chain.org</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Contact;
