import React from "react";
import { Box, Container } from "@mui/material";
import Section from "./Section";

function Layout({ children, py = 6, px = 4 }) {
  return (
    <Section p={py}>
      <Container maxWidth="xl">
        <Box px={px}>{children}</Box>
      </Container>
    </Section>
  );
}

export default Layout;
