//import logo from './logo.svg';
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./config/theme";

import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Mission from "./components/Mission";
import Platform from "./components/Platform";
import CaseStudy from "./components/CaseStudy";
import Team from "./components/Team";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Hero />
      <Mission />
      <Platform />
      <CaseStudy />
      <Team />
      <Contact />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
