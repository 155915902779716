import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";

function Card({ title, Icon, text }) {
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", left: "15px", top: "15px" }}>
        {Icon ? <Icon height="40px" width="40px" /> : null}
      </Box>
      <Box
        component="div"
        sx={{
          borderRadius: "20px 20px 0px 0px",
          height: 70,
          mb: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        bgcolor={theme.palette.secondary.main}
      >
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
}

export default Card;
