import React from "react";
import Layout from "./Layout";
import { Box, Grid, Typography } from "@mui/material";

import bancoAlimentare from "../assets/bancoAlimentare.jpeg";

function CaseStudy() {
  return (
    <Layout>
      <Typography variant="h2" sx={{ textAlign: "center", mb: 4 }}>
        Casi d'uso
      </Typography>
      <Grid container spacing={6}>
        <Grid item md={6}>
          <Box
            component="img"
            src={bancoAlimentare}
            sx={{
              borderRadius: "20px",
              width: "100%",
              aspectRatio: 1,
              objectFit: "cover",
            }}
          ></Box>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h3" sx={{ mb: 4, mt: 2 }}>
            Banco Alimentare
          </Typography>
          <Typography variant="body1" gutterBottom>
            C-Chain supporterà Banco Alimentare Umbria durante la sua campagna
            della Colletta Alimentare.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Tutti i prodotti alimentari donati verranno registrati all'interno
            della piattaforma C-Chain e i donatori potranno monitorarne gli
            spostamenti dai Punti di Raccolta aderenti della Grande
            Distribuzione, ai magazzini di Banco Alimentare, fino alle
            Organizzazioni Territoriali. La blockchain garantirà immutabilità
            delle donazioni registrate e offrirà piena trasparenza. Questo
            aumenterà la fiducia dei donatori nei confronti della campagna.
          </Typography>
          <Typography variant="body1">
            Dal punto di vista organizzativo, la digitalizzazione e
            automatizzazione della raccolta dati ridurrà la frequenza degli
            errori umani durante la registrazione dei prodotti donati e
            faciliterà le operazioni di tutti i volontari nei Punti di Raccolta.
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default CaseStudy;
