import React from "react";
import Layout from "./Layout";
import { Box, Grid, Typography } from "@mui/material";

import itt from "../assets/itt-terni.jpeg";
import team from "../assets/team.jpeg";
import maglietta from "../assets/t-shirt.jpg";

function Team() {
  return (
    <Layout>
      <Box id="teamSec">
        <Typography variant="h2" sx={{ textAlign: "center", mb: 4 }}>
          Il Team
        </Typography>
        <Grid container spacing={6}>
          <Grid
            item
            md={6}
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
          >
            <Box>
              <Typography variant="h3" sx={{ mb: 4, mt: 2 }}>
                Supernova Contest
              </Typography>
              <Typography variant="body1">
                C-Chain nasce all’interno del Supernova Contest, un incubatore
                di idee e di progettualità realizzato nell’Istituto Tecnico
                Tecnologico Allievi Sangallo di Terni, sponsorizzato dalla
                Fondazione Carit e dalla Borsa di Studio Famiglia Angeli.
              </Typography>
            </Box>

            <Box>
              <Typography variant="h3" sx={{ mb: 4, mt: 2 }}>
                Il gruppo
              </Typography>
              <Typography variant="body1">
                Il team di sviluppo è composto da studenti del triennio,
                specializzazione informatica, supervisionato da un gruppo di
                docenti.
              </Typography>
            </Box>
          </Grid>

          <Grid item md={6} alignContent="center">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  component="img"
                  src={itt}
                  sx={{
                    borderRadius: "20px 20px 0px 0px",
                    width: "100%",
                    aspectRatio: "50%",
                    objectFit: "cover",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="img"
                  src={team}
                  sx={{
                    borderRadius: "0px 0px 0px 20px",
                    width: "100%",
                    aspectRatio: "1",
                    objectFit: "cover",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="img"
                  src={maglietta}
                  sx={{
                    borderRadius: "0px 0px 20px 0px",
                    width: "100%",
                    aspectRatio: "1",
                    objectFit: "cover",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}

export default Team;
